import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '../../Common/TextField';
import Link from '../../Common/Link';
import Button from '../../Common/Button';
import TitleForm from '../../Common/Title';
import SubTitle from '../../Common/SubTitle';
import { actions } from '../action';
import { BLUE_BUTTOM, WHITE } from '../../../constants/colors.styles';
import { forgotPassword } from '../../../services/signup';
import { errorMessagesRecoveryPassword } from '../../../utils/MessageError';
import {
  URL_LOGIN,
  URL_CHECK_TOKEN_RECOVERY,
} from '../../../constants/http.constants';

const useStyles = makeStyles(() => ({
  link: {
    color: BLUE_BUTTOM,
    fontSize: 12,
    textAlign: 'end',
    marginTop: '10%',
  },
  arrow: {
    fontSize: 10,
    margin: -2,
  },
  contentNull: {
    width: 500,
  },
}));

export default function RecoveryPassword() {
  const { formatMessage } = useIntl();
  const success = useSelector((state) => state.signup.success);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const emailInputRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [subTitleText, setSubTitle] = useState('SUBTITLE_RECOVERY_PASS');

  const onSubmit = async () => {
    if (!email) {
      setError(true);
      setSubTitle('REQUIRED_FIELD');
      return;
    }

    try {
      setIsDisabled(true);
      const resp = await forgotPassword({ email });
      localStorage.setItem('tokenValidationEmail', email);

      if (resp.status === 200) history.push(URL_CHECK_TOKEN_RECOVERY);
    } catch (err) {
      setIsDisabled(false);
      setError(true);
      setSubTitle(errorMessagesRecoveryPassword[err.response.data.messages[0]]);
    }
  };

  useEffect(() => {
    dispatch(actions.success(''));
    dispatch(actions.step(0));
    emailInputRef.current.focus();
  }, [dispatch, emailInputRef]);

  useEffect(() => {
    if (email) setSubTitle('SUBTITLE_RECOVERY_PASS');
    setError(false);
  }, [email]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        onSubmit();
      }}
    >
      <TitleForm error={error} id="tit-recovery-password">
        {formatMessage({ id: 'TITLE_RECOVERY_PASS' })}
      </TitleForm>
      <div style={{ width: 300 }}>
        <SubTitle error={error} id="sub-recovery-password">
          {subTitleText ? formatMessage({ id: subTitleText }) : null}
        </SubTitle>
      </div>
      <Grid container alignItems="flex-end" justify="flex-end">
        <Grid item sm={12} xs={12}>
          <TextField
            id="inp-email-recovery-password"
            inputRef={emailInputRef}
            style={{ marginTop: 0 }}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e, validator) => setError(!validator)}
            type="email"
            name="email"
            error={success === 'error' || error}
            label={formatMessage({ id: 'YOUR_EMAIL' })}
          />
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          justify="flex-end"
        >
          <Grid item md={5} sm={5} xs={12}>
            <Button
              id="btn-submit-recovery-password"
              color="primary"
              label={formatMessage({ id: 'RECOVERY' })}
              style={{
                fontSize: 12,
                padding: 10,
                backgroundColor: BLUE_BUTTOM,
                color: WHITE,
              }}
              startIcon=""
              endIcon={
                isDisabled ? (
                  <CircularProgress size={15} style={{ color: WHITE }} />
                ) : (
                  ''
                )
              }
              disabled={isDisabled}
            />
          </Grid>
          <Grid item sm={12} xs={12} className={classes.link}>
            <Link to={URL_LOGIN}>
              <ArrowBackIosIcon color="primary" className={classes.arrow} />
              <ArrowBackIosIcon color="primary" className={classes.arrow} />
              {formatMessage({ id: 'BACK' })}
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.contentNull} />
    </form>
  );
}
