export const getActingProfile = () => {
  const actingProfile = JSON.parse(
    localStorage.getItem('acting_profile') || '{}'
  );

  return actingProfile;
};

export const getUserInfo = () => {
  const actingProfile = getActingProfile();
  const userInfo = actingProfile.info;

  if (userInfo?.avatar?.includes('avatar.svg')) userInfo.avatar = undefined;

  return userInfo;
};

export const getUserOptions = () => {
  const params = new URLSearchParams(window.location.search);
  return { start_recording: params.get('start_recording') };
};
