/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import MobileStepper from '@material-ui/core/MobileStepper';
import { WHITE, YELLOW } from '../../../constants/colors.styles';

const useStyles = makeStyles(() => ({
  stepper: {
    display: 'grid',
    padding: 20,
    background: 'transparent',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',

    '& .MuiMobileStepper-dot': {
      width: 6,
      height: 6,
      margin: '0 8px',
      borderRadius: '50%',
      backgroundColor: WHITE,
    },

    '& .MuiMobileStepper-dotActive': {
      backgroundColor: YELLOW,
    },
  },
}));

export default function Detail({ src, size, totalSteps }) {
  const classes = useStyles();
  const step = useSelector((state) => state.signup.step);

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item lg={10} md={10} sm={10} xs={10}>
        <img src={src} alt="" width={size} id="img-detail" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {totalSteps > 0 ? (
          <MobileStepper
            variant="dots"
            steps={totalSteps}
            position="static"
            className={classes.stepper}
            activeStep={step}
          />
        ) : (
          <Grid item sm={12} xs={12} style={{ width: 200 }} />
        )}
      </Grid>
    </Grid>
  );
}

Detail.propTypes = {
  src: PropTypes.node.isRequired,
  size: PropTypes.number,
  totalSteps: PropTypes.number.isRequired,
};
