import axios from 'axios';
import { getAuthToken } from 'utils/auth';
import { API_URL } from '../constants/http.constants';

const HTTP_CLIENT_TIMEOUT = 6000;

const errorHandlerInterceptor = (err) => Promise.reject(err);
const responseHandlerInterceptor = (response) => response.data;
const tokenInterceptor = async (config) => {
  const token = getAuthToken();
  config.headers['Content-Type'] = 'application/json';
  config.headers.Authorization = `Bearer ${token}`;

  return config;
};

const client = axios.create({
  baseURL: API_URL,
  timeout: HTTP_CLIENT_TIMEOUT,
});

client.interceptors.response.use(
  responseHandlerInterceptor,
  errorHandlerInterceptor
);

client.interceptors.request.use(tokenInterceptor, errorHandlerInterceptor);

export default client;
