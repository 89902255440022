import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { hideDialog } from '../../actions/general';

function AlertDialog() {
  const { isDialogOpen: isOpen, dialogData } = useSelector(
    (state) => state.general
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideDialog());
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogData.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogData.firstButton && (
            <Button
              onClick={dialogData.firstButtonClick || handleClose}
              color="primary"
            >
              {dialogData.firstButton}
            </Button>
          )}
          {dialogData.secondButton && (
            <Button
              onClick={dialogData.secondButtonClick || handleClose}
              color="primary"
              autoFocus
            >
              {dialogData.secondButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
