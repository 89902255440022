import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import MainFrame from '../MainFrame';
import TitleForm from '../Common/Title';
import SubTitle from '../Common/SubTitle';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 5),
    textAlign: 'center',
  },
  main: {
    maxWidth: 800,
  },
}));

export default function LoginErrorSSO() {
  const { formatMessage } = useIntl();
  const [error] = useState(true);
  const [title] = useState(formatMessage({ id: 'ERR_LOGIN_SSO_TITLE' }));
  const [subTitle] = useState(formatMessage({ id: 'ERR_LOGIN_SSO_TEXT' }));

  const classes = useStyles();

  return (
    <MainFrame id="login-error-sso" hideHeader hideFooter>
      <Container component="main" className={classes.main}>
        <Paper className={classes.paper}>
          <br />
          <TitleForm
            error={error}
            id="tit-guest"
            textAlign="center"
            fontSize="22"
            whiteSpace="pre-line"
          >
            {title}
          </TitleForm>
          <SubTitle
            error={error}
            id="sub-guest"
            textAlign="center"
            fontSize="18"
          >
            {subTitle}
          </SubTitle>
        </Paper>
      </Container>
    </MainFrame>
  );
}
