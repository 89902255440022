/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import {
  isChrome,
  isFirefox,
  isChromium,
  isMobileSafari,
} from 'react-device-detect';

import isElectron from 'utils/isElectron';

import { APP_TITLE } from '../../constants/http.constants';

const useStyles = makeStyles(() => ({
  browserAlert: {
    color: '#FF4B36',
  },
}));

export default function HomologBrowser() {
  const classes = useStyles();

  return (
    <div>
      {!isChrome &&
        !isFirefox &&
        !isChromium &&
        !isMobileSafari &&
        !isElectron && (
          <span className={classes.browserAlert}>
            <FormattedMessage
              id="MSG_HOMOLOGATED_BROWSER"
              values={{ app_title: APP_TITLE }}
            />
          </span>
        )}
    </div>
  );
}
