import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Guest from './components/Guest';
import ConfirmInvite from './components/ConfirmInvite';
import { Meet } from './components/Meet';
import Auth from './components/Auth';
import BaseForm from './components/Signup/Common/BaseForm';
import { checkAuth } from './utils/auth';
import Dialog from './components/Common/Dialog';
import SupportedBrowser from './components/SupportedBrowser';
import FileDownload from './components/FileDownload';
import ThirdPartyAccessRoute from './components/ThirdPartyAccessRoute';
import LoginErrorSSO from './components/LoginErrorSSO';

import './App.scss';
import 'typeface-roboto';
import ClearLocalStorage from 'components/ClearLocalStorage';

function App({ store, history }) {
  return (
    <Provider store={store}>
      <Grid container className="app">
        <Router history={history}>
          <Route exact path="/" component={() => checkAuth()} />
          <Route
            exact
            path="/file/:fileId/download_link"
            component={FileDownload}
          />
          <Route exact path="/auth" component={Auth} />
          <ThirdPartyAccessRoute
            exact
            path="/meet/:roomName"
            component={Meet}
          />
          <Route exact path="/supported-browser" component={SupportedBrowser} />

          <Route exact path="/guest" component={Guest} />
          <Route exact path="/confirm-invite" component={ConfirmInvite} />
          <Route exact path="/user/:componentUrl" component={BaseForm} />
          <Route exact path="/clear" component={ClearLocalStorage} />
          <Route exact path="/login-error-sso" component={LoginErrorSSO} />
        </Router>
      </Grid>

      <Dialog />
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default App;
