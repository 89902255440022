import { SUCCESS, STEP, RESPONSE, TRANSITION } from '../constants/action.types';

const INITIAL_STATE = {
  success: '',
  step: 0,
  response: {
    open: false,
    message: '',
    variant: 'success',
  },
  isTransition: true,
};

// eslint-disable-next-line default-param-last
const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUCCESS:
      return { ...state, success: action.success };
    case STEP:
      return { ...state, step: action.step };
    case RESPONSE:
      return { ...state, response: action.response };
    case TRANSITION:
      return { ...state, isTransition: action.isTransition };
    default:
      return state;
  }
};

export default reducers;
