import { createStore } from 'redux';
import reducer from './reducer';

const configureStore = () => {
  const store = createStore(
    reducer,
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  return store;
};

export default configureStore;
