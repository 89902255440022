import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { useIntl } from 'react-intl';
import useInviteToken from 'hooks/useInviteToken';

import log from 'utils/log';
import { saveAuthorization } from 'utils/auth';
import { createUser } from 'services/signup';
import { URL_WELCOME } from 'constants/http.constants';
import TitleForm from '../Common/Title';
import SubTitle from '../Common/SubTitle';
import LinearProgressWithLabel from '../Common/LinearProgressWithLabel';
import MainFrame from '../MainFrame';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 5),
    textAlign: 'center',
  },
  main: {
    maxWidth: 600,
  },
}));

let timer;

export default function ConfirmInvite() {
  const { formatMessage } = useIntl();
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [title, setTitle] = useState(
    formatMessage({ id: 'INVITE_VALIDATING_TITLE' })
  );
  const [subTitle, setSubTitle] = useState(
    formatMessage({ id: 'INVITE_VALIDATING_TEXT' })
  );
  const [inviteToken, invitedEmail, expiredToken] = useInviteToken();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (inviteToken) {
      setShowProgress(true);
      timer = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 10);
      }, 500);
    }

    if (expiredToken) {
      setError(true);
      setTitle(formatMessage({ id: 'INVITE_EXPIRED_TITLE' }));
      setSubTitle(formatMessage({ id: 'INVITE_EXPIRED_TEXT' }));
    }

    return () => {
      clearInterval(timer);
    };
  }, [inviteToken, expiredToken, formatMessage]);

  /**
   * Let's fake a progress here ;)
   */
  useEffect(() => {
    // If progress 30% we fire the request
    if (progress === 30) {
      (async () => {
        try {
          const res = await createUser({
            email: invitedEmail,
            invite_token: inviteToken,
          });
          const { token, profiles } = res.data;
          saveAuthorization(token, profiles);
        } catch (err) {
          const { status, data } = err.response;

          log.logger(err);
          setError(true);
          clearInterval(timer);
          setShowProgress(false);

          let title;
          let text;
          if (status === 409) {
            title = formatMessage({ id: 'INVITE_ACCEPTED_TITLE' });
            text = formatMessage({ id: 'INVITE_ACCEPTED_TEXT' });
          } else {
            title = formatMessage({ id: 'INVITE_ERR_TITLE' });
            text = `${formatMessage({ id: 'INVITE_ERR_TEXT' })} ${
              data.messages[0]
            }`;
          }

          setTitle(title);
          setSubTitle(text);
        }
      })();
    }

    // When progress 100% we show the success message
    if (progress === 100) {
      setTitle(formatMessage({ id: 'INVITE_SUCCESS_TITLE' }));
      setSubTitle(formatMessage({ id: 'INVITE_SUCCESS_TEXT' }));
      clearInterval(timer);

      setTimeout(() => {
        clearInterval(timer);
        history.push(URL_WELCOME);
      }, 3000);
    }
  }, [formatMessage, history, inviteToken, invitedEmail, progress]);

  return (
    <MainFrame id="confirm-invite">
      <Container component="main" className={classes.main}>
        <Paper className={classes.paper}>
          <br />
          <TitleForm
            error={error}
            id="tit-guest"
            textAlign="center"
            whiteSpace="pre-line"
          >
            {title}
          </TitleForm>
          <SubTitle error={error} id="sub-guest" textAlign="center">
            {subTitle}
          </SubTitle>

          {showProgress && <LinearProgressWithLabel value={progress} />}
        </Paper>
      </Container>
    </MainFrame>
  );
}
