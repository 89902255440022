import { SHOW_DIALOG, HIDE_DIALOG } from '../constants/action.types';

const INITIAL_STATE = {
  isDialogOpen: false,
  dialogData: {
    title: 'Some title',
    text: 'Some text',
    firstButton: null,
    secondButton: null,
    firstButtonClick: null,
    secondButtonClick: null,
  },
};

// eslint-disable-next-line default-param-last
const general = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        ...state,
        isDialogOpen: true,
        dialogData: action.payload,
      };
    case HIDE_DIALOG:
      return {
        ...state,
        isDialogOpen: false,
      };
    default:
      return state;
  }
};

export default general;
