import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HomologBrowser from '../HomologBrowser';
import Link from '../Common/Link';
// import { FormattedMessage } from 'react-intl';
// import { APP_TITLE } from '../../constants/http.constants';

const useStyles = makeStyles(() => ({
  footer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#0088c1',
    color: '#f2f2f2',
    fontSize: 12,
    padding: '15px 0 15px',
    userSelect: 'none',
  },
  links: {
    padding: '15px 0 15px 0',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const [year, setYear] = useState('');

  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, [year]);

  return (
    <div className={classes.footer}>
      <span>
        Copyright © {year} Synacor, Inc. All rights reserved.
        {/* <FormattedMessage
          id="COPY_RIGHT"
          values={{ app_title: APP_TITLE }}
        />{' '} */}
        <div className={classes.links}>
          <Link
            to="#"
            style={{ color: '#ffffff' }}
            onClick={() => {
              window.open('https://www.zimbra.com/legal/', '_blank');
            }}
          >
            Legal Information
          </Link>
          {' | '}
          <Link
            to="#"
            style={{ color: '#ffffff' }}
            onClick={() => {
              window.open('https://www.synacor.com/privacy-policy/', '_blank');
            }}
          >
            Privacy Policy
          </Link>
          {' | '}
          <Link
            to="#"
            style={{ color: '#ffffff' }}
            onClick={() => {
              window.open('https://synacor.com/Do-Not-Sell', '_blank');
            }}
          >
            Do Not Sell My Personal Information
          </Link>
          {' | '}
          <Link
            to="#"
            style={{ color: '#ffffff' }}
            onClick={() => {
              window.open('https://synacor.com/personal-data', '_blank');
            }}
          >
            CCPA Disclosures
          </Link>
        </div>
        <HomologBrowser />
      </span>
    </div>
  );
}
