export const GET = 'get';
export const PUT = 'put';
export const POST = 'post';
export const DELETE = 'delete';
export const APP_TITLE = process.env.REACT_APP_TITLE;
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;

export const VIDEO_CONFERENCE_GUEST = 'v?r=';
export const MEET_DOMAIN = process.env.REACT_APP_MEET_DOMAIN;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const PUBLIC_SIGNUP = process.env.REACT_APP_PUBLIC_SIGNUP !== 'false';
export const LOGO_NAME = process.env.REACT_APP_LOGO_NAME || 'logo-immail.png';
export const SHOW_LOGO = process.env.REACT_APP_SHOW_LOGO !== 'false';

export const CHECK_TOKEN_RECOVERY = 'check-token-recovery';
export const CHECK_TOKEN = 'check-token';

export const URL_CHECK_TOKEN_RECOVERY = '/user/check-token-recovery';
export const URL_CHECK_TOKEN = '/user/check-token';
export const URL_WELCOME = '/user/welcome';
export const URL_ADD_TEAMMATES = '/user/add-teammates';
export const URL_SIGNUP = '/user/signup';
export const URL_UPDATE_PASSWORD = '/user/update-password';
export const URL_RECOVERY_PASSWORD = '/user/recovery-password';
export const URL_LOGIN = '/user/login';

export const LOCALE =
  navigator.language.split('-')[0] === 'pt' ? 'pt-BR' : 'en-US';

export const getUrlDownloadChrome = () =>
  `https://www.google.com/intl/${LOCALE}/chrome/`;
export const getUrlDownloadFirefox = () =>
  `https://www.mozilla.org/${LOCALE}/firefox/new/`;
