/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import queryString from 'query-string';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, useHistory } from 'react-router-dom';
import log from 'utils/log';
import Link from '../Common/Link';

import { createGuest } from '../../services/guest';
import TextField from '../Common/TextField';
import Button from '../Common/Button';
import Checkbox from '../Common/Checkbox';
import { BLUE_BUTTOM, WHITE } from '../../constants/colors.styles';
import TitleForm from '../Common/Title';
import SubTitle from '../Common/SubTitle';
import MainFrame from '../MainFrame';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 5),
    textAlign: 'center',
  },
  avatar: {
    backgroundColor: BLUE_BUTTOM,
    width: '80px',
    height: '80px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  main: {
    maxWidth: 400,
  },
}));

export default function Guest() {
  const { formatMessage } = useIntl();
  const [name, setName] = useState(null);
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [subTitleText, setSubTitle] = useState('IMMAIL_GUEST');

  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();

  const sendForm = async () => {
    setIsDisabled(true);

    if (!name || !terms) {
      setError(true);
      setSubTitle('REQUIRED_FIELD');
      setIsDisabled(false);
      return;
    }

    try {
      const guest = await createGuest({
        name,
        accepted_terms: {
          version: 1,
        },
      });

      if (!guest) {
        setSubTitle('MSG_GUEST_ERR');
        setError(true);
        setIsDisabled(false);
        return;
      }
      localStorage.setItem('guestId', guest._id);

      if (location.state) {
        history.push({ ...location.state.from });
        return;
      }

      const params = queryString.parse(window.location.search);
      if (params.vc_link) {
        window.location.href = params.vc_link;
        return;
      }

      setError(true);
      setSubTitle('MSG_GUEST_REDIRECT_ERR');
      setIsDisabled(false);
    } catch (err) {
      log.logger(err);

      setError(true);
      setIsDisabled(false);
    }
  };

  return (
    <MainFrame id="root-guest">
      <Container component="main" className={classes.main} id="ctn-guest">
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar} id="avt-guest">
            <AccountCircle style={{ fontSize: 80 }} id="acc-guest" />
          </Avatar>
          <br />
          <TitleForm
            error={error}
            id="tit-guest"
            textAlign="center"
            whiteSpace="pre-line"
          >
            {formatMessage({ id: 'WELCOME_TITLE' })}
          </TitleForm>
          <SubTitle error={error} id="sub-guest" textAlign="center">
            {subTitleText ? formatMessage({ id: subTitleText }) : null}
          </SubTitle>
          <form
            className={classes.form}
            onSubmit={async (e) => {
              e.preventDefault();
              sendForm();
            }}
            autoComplete="off"
            id="frm-guest"
          >
            <TextField
              error={error}
              name="name"
              id="inp-name-guest"
              label={formatMessage({ id: 'TYPE_YOUR_NAME' })}
              onChange={(e) => {
                setError(false);
                setSubTitle('IMMAIL_GUEST');
                setName(e.target.value);
              }}
            />
            <Checkbox
              error={error}
              id="ckb-accepted-terms-guest"
              value="accepted_terms"
              checked={terms}
              onChange={(e) => {
                setError(false);
                setSubTitle('IMMAIL_GUEST');
                setTerms(e.target.checked);
              }}
            />
            <Link
              id="lnk-privacy-policy-guest"
              to="#"
              onClick={() =>
                window.open(formatMessage({ id: 'URL_PRIVACY_POLICY' }))
              }
              style={{ textTransform: 'unset' }}
            >
              {formatMessage({ id: 'PRIVACY_POLICY' })}
            </Link>
            <br />
            <br />
            <Button
              id="btn-sen-guest"
              color="primary"
              label={formatMessage({ id: 'CONTINUE' })}
              style={{
                fontSize: 12,
                padding: 10,
                fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif', sans-serif`,
                backgroundColor: BLUE_BUTTOM,
                color: WHITE,
              }}
              disabled={isDisabled}
              startIcon=""
              endIcon={
                isDisabled ? (
                  <CircularProgress size={15} style={{ color: WHITE }} />
                ) : (
                  <ArrowForwardIcon />
                )
              }
            />
          </form>
        </Paper>
      </Container>
    </MainFrame>
  );
}
