export const TRANSITION_TIME = 500;
export const TRANSITION_TIMEOUT = 5000;

export const clipBoardIOS = (copy) => {
  const textArea = document.createElement('textArea');
  textArea.readOnly = true;
  textArea.contentEditable = true;
  textArea.value = copy;
  document.body.appendChild(textArea);

  const range = document.createRange();
  range.selectNodeContents(textArea);

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  textArea.setSelectionRange(0, 999999);

  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const importJitsiApi = () => {
  const src = `https://${process.env.REACT_APP_MEET_DOMAIN}/external_api.js`;
  return new Promise((resolve) => {
    if (window.JitsiMeetExternalAPI) {
      resolve(window.JitsiMeetExternalAPI);
    }
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');

    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', src);

    head.addEventListener(
      'load',
      (event) => {
        if (event.target.src === src) {
          resolve(window.JitsiMeetExternalAPI);
        }
      },
      true
    );
    head.appendChild(script);
  });
};
