import {
  STEP,
  RESPONSE,
  SUCCESS,
  TRANSITION,
} from '../../constants/action.types';

const actions = {
  success: (index) => ({
    type: SUCCESS,
    success: index,
  }),
  step: (index) => ({
    type: STEP,
    step: index,
  }),
  response: (data) => ({
    type: RESPONSE,
    response: data,
  }),
  isTransition: (data) => ({
    type: TRANSITION,
    isTransition: data,
  }),
};

export { actions };
