import * as Sentry from '@sentry/browser';

const sentryLogger = (err) => {
  const envsEnable = ['alpha', 'production'];

  const profile = JSON.parse(localStorage.getItem('acting_profile'));
  const guestId = localStorage.getItem('guestId');
  if (profile && profile.info) Sentry.setUser({ email: profile.info.email });
  if (guestId) Sentry.setUser({ guestId });

  if (envsEnable.includes(process.env.REACT_APP_ENV))
    Sentry.captureException(err);
};

export default sentryLogger;
