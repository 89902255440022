import axios from 'axios';
import { GET, API_URL } from '../constants/http.constants';

export const getFileValidatingShareLink = async ({ fileId, shareKey }) =>
  axios({
    method: GET,
    url: `${API_URL}/file/${fileId}/validating_link?share_key=${shareKey}`,
  });

export const getFileDownloadShareLink = async ({ fileId, shareKey }) =>
  axios({
    method: GET,
    url: `${API_URL}/file/${fileId}/download_link?share_key=${shareKey}`,
  });
