/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Button2 from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '2px',
    padding: theme.spacing(2),
    fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif', sans-serif`,
  },
}));

const Button = React.forwardRef((props, ref) => {
  const {
    onClick,
    label,
    fullWidth,
    startIcon,
    endIcon,
    type,
    color,
    style,
    size,
    disabled,
    id,
    href,
    variant,
    disableRipple,
  } = props;
  const classes = useStyles();

  return (
    <Button2
      fullWidth={fullWidth}
      type={type}
      aria-label="enter"
      style={style}
      variant={variant}
      size={size}
      className={classes.button}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      color={color}
      disabled={disabled}
      id={id}
      href={href}
      ref={ref}
      disableRipple={disableRipple}
    >
      {label}
    </Button2>
  );
});

Button.propTypes = {
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  color: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.string,
  disableRipple: PropTypes.bool,
};

Button.defaultProps = {
  fullWidth: true,
  label: '',
  type: 'submit',
  variant: 'contained',
  disableRipple: false,
  onClick: () => {},
};

export default Button;
