import axios from 'axios';
import client from './client';
import { GET, POST, API_URL } from '../constants/http.constants';

export const getConversation = (conversationId) =>
  client({
    method: GET,
    url: `/conversation/${conversationId}`,
  });

export const getRoomToken = (roomName) =>
  client({
    method: POST,
    url: '/v2/videoconference/token',
    data: {
      room_name: roomName,
    },
  });

export const getRoomTokenGuest = async (roomName) => {
  const res = await axios({
    method: POST,
    url: `${API_URL}/v2/videoconference/guest/token`,
    data: {
      room_name: roomName,
    },
  });
  return res.data;
};

export const getRoom = (roomName) =>
  client({
    method: POST,
    url: '/v2/videoconference/room',
    data: {
      room_name: roomName,
    },
  });
