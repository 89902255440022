import * as Sentry from '@sentry/browser';
import { APP_TITLE, RECAPTCHA_SITE_KEY } from 'constants/http.constants';
import ReactDOM from 'react-dom/client';
import Favicon from 'react-favicon';
import ReactGA from 'react-ga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IntlProvider } from 'react-intl';
import history from './config/history';
import configureStore from './config/store';
import langs from './lang';
import { flattenMessages } from './utils/flattenMessages';

import App from './App';
import FaviconIco from './imgs/favicon.ico';
import './index.scss';

// CHECK ON-PREMISES
ReactGA.initialize('UA-123090481-1', {
  gaOptions: {
    name: 'web-react',
  },
});

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const store = configureStore();
const locale = navigator.language.split('-')[0];
const langMessages = langs[locale] || langs.en;

// CHECK ON-PREMISES
// Initiate the logger
if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://9ad790631c0740f8b51b0d6b8e1d458d@o405373.ingest.sentry.io/5270879',
    environment: process.env.REACT_APP_ENV,
  });
}

document.title = APP_TITLE;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey={RECAPTCHA_SITE_KEY}>
    <IntlProvider locale={locale} messages={flattenMessages(langMessages)}>
      <Favicon url={FaviconIco} />
      <App store={store} history={history} />
    </IntlProvider>
  </GoogleReCaptchaProvider>
);
