/* eslint-disable no-prototype-builtins */
import queryString from 'query-string';
import { decodeToken } from 'react-jwt';
import { APP_URL, URL_LOGIN } from '../constants/http.constants';

const authHeader = () => {
  const authorization = localStorage.getItem('authorization');

  if (authorization) {
    return { Authorization: `Bearer ${authorization}` };
  }
  return {};
};

const checkIsLoggedIn = () => !!localStorage.getItem('authorization');

const checkAuth = () => {
  window.location.href = checkIsLoggedIn() ? APP_URL : URL_LOGIN;
};

const checkAuthOrGuest = (history, location) => {
  if (
    !localStorage.getItem('authorization') &&
    !localStorage.getItem('guestId')
  ) {
    history.push({
      pathname: '/guest',
      state: { from: location },
      search: `?vc_link=${window.location.href}`,
    });
    return true;
  }
  return false;
};

const checkAuthorization = () => {
  if (localStorage.getItem('authorization')) {
    const params = new URLSearchParams(window.location.search);

    if (params.has('token')) {
      const previousParams = queryString.parse(window.location.search);
      delete previousParams.token;
      const search = Object.keys(previousParams).reduce(
        (acc, key) => `${acc}${key}=${previousParams[key]}&`,
        '?'
      );

      window.history.pushState({}, document.title, `/${search}`);
    }
  }

  return localStorage.getItem('authorization');
};

const saveAuthorization = (token, profiles) => {
  const data = decodeToken(token);
  localStorage.clear();

  localStorage.setItem('authorization', JSON.stringify(token));
  localStorage.setItem('user', JSON.stringify(data.user));
  localStorage.setItem('preferences', JSON.stringify(data.user.preferences));

  if (profiles) {
    localStorage.setItem('profiles', JSON.stringify(profiles));
    localStorage.setItem('acting_profile', JSON.stringify(profiles[0]));
  }
};

const getUser = () => {
  const user = localStorage.getItem('user');

  return user ? JSON.parse(user) : null;
};

const checkTokenExpiration = (token) => {
  const { exp } = decodeToken(token);

  return Date.now() <= exp * 1000;
};

const getAuthToken = () => {
  const params = new URLSearchParams(window.location.search);

  if (params.has('token')) return params.get('token').replace(/"/g, '');

  const token = localStorage.getItem('authorization');
  return token ? token.replace(/"/g, '') : null;
};

export {
  authHeader,
  checkIsLoggedIn,
  checkAuthorization,
  saveAuthorization,
  getUser,
  checkAuth,
  checkAuthOrGuest,
  checkTokenExpiration,
  getAuthToken,
};
