/* eslint-disable react/require-default-props */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { GREY_SUBTITLE, RED_ERROR } from '../../constants/colors.styles';

const useStyles = makeStyles(() => ({
  subtitle: {
    color: GREY_SUBTITLE,
    margin: '10px 0 10px 0',
  },
}));

export default function SubTitle({
  error,
  children,
  id,
  textAlign = 'start',
  fontSize = 12,
}) {
  const classes = useStyles();

  return (
    <div
      id={id}
      className={classes.subtitle}
      style={{ color: error ? RED_ERROR : GREY_SUBTITLE, textAlign, fontSize }}
    >
      {children}
    </div>
  );
}

SubTitle.propTypes = {
  error: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.number,
};
