import queryString from 'query-string';
import { decodeToken as reactJwtDecodeToken } from 'react-jwt';

const DEFAULT_TOOLBAR_BUTTONS = [
  'camera',
  'chat',
  'closedcaptions',
  'desktop',
  // 'download',
  // 'embedmeeting',
  // 'etherpad',
  // 'feedback',
  'filmstrip',
  'fullscreen',
  'hangup',
  // 'help',
  'invite',
  'livestreaming',
  'microphone',
  'mute-everyone',
  'mute-video-everyone',
  'participants-pane',
  // 'profile',
  'raisehand',
  'recording',
  'security',
  'select-background',
  'settings',
  // 'shareaudio',
  'sharedvideo',
  'shortcuts',
  'stats',
  'tileview',
  'toggle-camera',
  'videoquality',
  // 'white-board',
];

const makeToolbarButtons = (defaultToolbar, hideButtons) => {
  if (!hideButtons) return defaultToolbar;
  return defaultToolbar.filter((btn) => !hideButtons.includes(btn));
};

export const getInterfaceConfig = () => {
  const params = queryString.parse(window.location.search, {
    parseBooleans: true,
    arrayFormat: 'comma',
  });

  const configs = {
    HIDE_INVITE_MORE_HEADER: params.HIDE_INVITE_MORE_HEADER,
    DISABLE_VIDEO_BACKGROUND: params.DISABLE_VIDEO_BACKGROUND,
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: params.DISABLE_JOIN_LEAVE_NOTIFICATIONS,
    DEFAULT_LOGO_URL: params.DEFAULT_LOGO_URL,
    SUPPORT_URL: params.SUPPORT_URL,
    TOOLBAR_ALWAYS_VISIBLE: params.TOOLBAR_ALWAYS_VISIBLE,
    SHOW_BRAND_WATERMARK: params.SHOW_BRAND_WATERMARK,
    BRAND_WATERMARK_LINK: params.BRAND_WATERMARK_LINK,
    APP_NAME: params.APP_NAME,
  };

  return Object.keys(configs)
    .filter((key) => configs[key] !== undefined)
    .reduce((obj, key) => {
      obj[key] = configs[key];
      return obj;
    }, {});
};

export const getConfig = () => {
  const params = queryString.parse(window.location.search, {
    parseBooleans: true,
    arrayFormat: 'comma',
  });
  const configs = {
    startWithAudioMuted: params.startWithAudioMuted,
    startWithVideoMuted: params.startWithVideoMuted,
    liveStreamingEnabled: params.liveStreamingEnabled,
    fileRecordingsEnabled: params.fileRecordingsEnabled,
    startScreenSharing: params.startScreenSharing,
    hideParticipantsStats: params.hideParticipantsStats,
    enableScreenshotCapture: params.enableScreenshotCapture,
    channelLastN: params.channelLastN,
    disableInviteFunctions: params.disableInviteFunctions,
    prejoinPageEnabled: params.prejoinPageEnabled,
    hideLogo: params.hideLogo || true,
    hideDesktopLink: params.hideDesktopLink || true,
    toolbarButtons: makeToolbarButtons(
      DEFAULT_TOOLBAR_BUTTONS,
      params.hideButtons
    ),
  };

  return Object.keys(configs)
    .filter((key) => configs[key] !== undefined)
    .reduce((obj, key) => {
      obj[key] = configs[key];
      return obj;
    }, {});
};

/**
 * -- new URLSearchParams(window.location.search)
 * @param {URLSearchParams} params - Url Search Params
 * @returns Object | undefined
 */
export const getInfoBySearchParams = (params) => {
  if (!params.toString()) return undefined;

  // Purge undefined key params.
  params.forEach((value, key) => {
    if (value === 'undefined') params.delete(key);
  });
  return {
    first_name: params.get('first_name'),
    last_name: params.get('last_name'),
    avatar: params.get('avatar'),
    email: params.get('email'),
    jwt: params.get('jwt'),
  };
};

export const decodeToken = (token) => {
  if (!token) return undefined;

  const decoded = reactJwtDecodeToken(token);
  return decoded;
};

/**
 * Timeout to start recording automatically
 *  in milliseconds
 */
export const TIMEOUT_START_RECORDING = 3000;

/**
 * Set Interval Attempts to start recording
 *  in milliseconds
 */
export const INTERVAL_ATTEMPTS_START_RECORDING = 2000 * 60;
