/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import { Link, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hideDialog, showDialog } from 'actions/general';
import { API_URL, MEET_DOMAIN, APP_TITLE } from 'constants/http.constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isDesktop, isElectron, isMobile } from 'react-device-detect';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getRoomToken, getRoomTokenGuest } from 'services/meetService';
import { getAuthToken } from 'utils/auth';
import {
  decodeToken,
  getConfig,
  getInfoBySearchParams,
  getInterfaceConfig,
  INTERVAL_ATTEMPTS_START_RECORDING,
  TIMEOUT_START_RECORDING,
} from 'utils/meet';
import meetEventsFactory from 'utils/meetEventsFactory';
import { getUserOptions } from 'utils/profile';
import { importJitsiApi } from 'utils/utils';

import log from 'utils/log';
import ImmailMeetLogo from '../../imgs/immail-meet.png';
// import Button from '../Common/Button';
import Footer from '../MainFrame/Footer';
import { DialogStartRecorderError } from './DialogInitRecorderError';

const encodeParams = (obj) => {
  const encodeParam = ([key, val]) => {
    if (typeof val === 'object')
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        JSON.stringify(val)
      )}`;
    return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  };
  const encodedParams = Object.entries(obj).map(encodeParam);
  return encodedParams.join('&');
};

let intervalAttemptRecordingId;
let attemptsRecorder = 0;
function Meet() {
  const history = useHistory();
  const location = useLocation();
  const paramsRef = useRef(
    getInfoBySearchParams(new URLSearchParams(location.search))
  );
  const [showErrorStartRecording, setShowErrorStartRecording] = useState(false);
  const [userOptions] = useState(getUserOptions());
  const [interfaceConfig] = useState(getInterfaceConfig());
  const [config] = useState(getConfig());
  const [meetJWT, setMeetJWT] = useState(null);
  const [meetUrlMobile, setMeetUrlMobile] = useState(null);
  const [meetUrlDesktop, setMeetUrlDesktop] = useState(null);
  const [meetUrlWeb, setMeetUrlWeb] = useState('');
  const [proceedWithMeetWeb, setProceedWithMeetWeb] = useState(false);
  const [title, setTitle] = useState('');
  const { roomName } = useParams();
  const meetContainerRef = useRef(null);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const apiRef = useRef(null);
  const optionsApiInstance = useRef('');

  // const apps = useMemo(() => {
  //   const baseURLDesktopApp =
  //     'https://downloads.immail.ca/immail-meet-desktop/immail-meet-lts';

  //   return {
  //     iOS: {
  //       text: formatMessage({ id: 'VIDEO_CONF_DOWNLOAD_APPLE' }),
  //       url: 'https://itunes.apple.com/us/app/keynote/id1551993510?mt=8',
  //     },
  //     Android: {
  //       text: formatMessage({ id: 'VIDEO_CONF_DOWNLOAD_ANDROID' }),
  //       url: 'https://play.google.com/store/apps/details?id=ca.immail.meet',
  //     },
  //     'Mac OS': {
  //       text: formatMessage({ id: 'VIDEO_CONF_DOWNLOAD' }),
  //       url: `${baseURLDesktopApp}.dmg`,
  //     },
  //     Windows: {
  //       text: formatMessage({ id: 'VIDEO_CONF_DOWNLOAD' }),
  //       url: `${baseURLDesktopApp}.exe`,
  //     },
  //     Linux: {
  //       text: formatMessage({ id: 'VIDEO_CONF_DOWNLOAD' }),
  //       url: `${baseURLDesktopApp}.deb`,
  //     },
  //   };
  // }, [formatMessage]);

  const dialogModal = useCallback(
    (KEY_TITLE, KEY_MESSAGE) => {
      dispatch(
        showDialog({
          title: formatMessage({
            id: KEY_TITLE,
          }),
          text: formatMessage({
            id: KEY_MESSAGE,
          }),
          firstButton: formatMessage({ id: 'TRY_AGAIN' }),
          firstButtonClick: () => {
            dispatch(hideDialog());
            window.location.reload();
          },
        })
      );
    },
    [dispatch, formatMessage]
  );

  const initMeetContent = useCallback(async () => {
    try {
      const JitsiApi = await importJitsiApi();
      apiRef.current = new JitsiApi(MEET_DOMAIN, {
        ...optionsApiInstance.current,
        parentNode: meetContainerRef.current,
      });

      if (apiRef.current) {
        const payload = decodeToken(meetJWT);
        const meetEvents = meetEventsFactory(apiRef.current);
        apiRef.current.executeCommand('subject', '');
        apiRef.current.addListener('videoConferenceJoined', () => {
          const { fileRecordingsEnabled } = config;
          const { start_recording } = userOptions;

          // If fileRecordingsEnabled undefined, let's ignore this param. The default is true.
          const startRecording =
            fileRecordingsEnabled === undefined
              ? start_recording === 'true'
              : fileRecordingsEnabled && start_recording === 'true';
          if (startRecording && payload?.moderator) {
            setTimeout(() => {
              meetEvents.startRecording({
                mode: 'file',
                immailToken: getAuthToken(),
              });
              intervalAttemptRecordingId = setInterval(() => {
                if (attemptsRecorder < 1) {
                  meetEvents.stopRecording({
                    mode: 'file',
                  });
                  meetEvents.startRecording({
                    mode: 'file',
                    immailToken: getAuthToken(),
                  });
                  attemptsRecorder += 1;
                  return;
                }
                setShowErrorStartRecording(true);
                clearInterval(intervalAttemptRecordingId);
              }, INTERVAL_ATTEMPTS_START_RECORDING);
            }, TIMEOUT_START_RECORDING);
          }
        });

        apiRef.current.addEventListener('recordingStatusChanged', (obj) => {
          const { on, mode, error } = obj;
          if (on && mode === 'file' && !error && intervalAttemptRecordingId)
            clearTimeout(intervalAttemptRecordingId);
        });

        window.addEventListener(
          'message',
          (event) => {
            const { type, options = {} } = event.data;
            if (type && typeof type === 'string' && meetEvents[type])
              meetEvents[type](options);
          },
          false
        );
      }
    } catch (error) {
      log.log('############:', error);
      dialogModal('WARNING', 'VIDEO_CONF_LOAD_ERROR');
    }

    return () => {
      if (apiRef.current) {
        apiRef.current.dispose();
        window.removeEventListener('message', false);
      }
    };
  }, [meetJWT, config, userOptions, dialogModal]);

  const handleOpenMeetWeb = useCallback(
    (event) => {
      event.preventDefault();
      if (isMobile) return setProceedWithMeetWeb(true);

      return window.open(meetUrlWeb, '_blank');
    },
    [meetUrlWeb]
  );

  // Define Options for meet API
  useEffect(() => {
    if (!meetJWT) return;

    const token = getAuthToken();
    const fullName = `${paramsRef.current?.first_name || ''} ${
      paramsRef.current?.last_name || ''
    }`.trim();
    const options = {
      roomName,
      userInfo: {
        token,
        apiUrl: API_URL,
        displayName: fullName,
      },
      interfaceConfigOverwrite: {
        CUSTOM_MEETING_URL: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        ...interfaceConfig,
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        SHOW_JITSI_WATERMARK: false,
        HIDE_DEEP_LINKING_LOGO: true,
      },
      jwt: meetJWT,
      configOverwrite: { ...config },
    };
    optionsApiInstance.current = options;
  }, [config, interfaceConfig, meetJWT, roomName]);

  useEffect(() => {
    if (!meetJWT) return;

    if (!isElectron && meetJWT && (proceedWithMeetWeb || isDesktop)) {
      log.log('Initialize meet on iframe.');
      initMeetContent();
      return;
    }
    const deepLinkParams = {};
    Object.entries(optionsApiInstance.current).forEach(([key, value]) => {
      if (typeof value !== 'object') {
        deepLinkParams[key] = value;
        return;
      }

      Object.entries(optionsApiInstance.current[key]).forEach(
        ([nestedKey, nestedValue]) => {
          deepLinkParams[`${key}.${nestedKey}`] = nestedValue;
        }
      );
    });
    const desktopLinkParams = JSON.parse(
      JSON.stringify(deepLinkParams)
        .replaceAll('configOverwrite.', 'config.')
        .replaceAll('interfaceConfigOverwrite', 'interfaceConfig')
    );

    const encodedParams = encodeParams({ jwt: meetJWT });
    const hashParams = encodeParams(desktopLinkParams);
    const deepLinking = `ca.immail.meet://${MEET_DOMAIN}/${roomName}?${encodedParams}`;
    const deepLinkDesktop = `immail-meet://${MEET_DOMAIN}/${roomName}?${encodedParams}#${hashParams}`;
    setMeetUrlMobile(deepLinking);
    setMeetUrlDesktop(deepLinkDesktop);

    const meetUrlWebLink = ''
      .concat(window.location.protocol)
      .concat('//')
      .concat(window.location.host)
      .concat(`/meet/${roomName}`)
      .concat(`?${encodedParams}`);
    setMeetUrlWeb(meetUrlWebLink);

    // const link = isMobile ? deepLinking : deepLinkDesktop;
    // Force continue in browser
    const link = meetUrlWebLink;

    if (isMobile || isElectron) window.location.href = link;
  }, [meetJWT, initMeetContent, roomName, config, proceedWithMeetWeb]);

  // Enforce Token
  useEffect(() => {
    // If jwt is provided, set meetJWT state
    if (paramsRef.current?.jwt) {
      setProceedWithMeetWeb(() => true);
      setMeetJWT(paramsRef.current.jwt);
      history.replace({ search: '' });
      return undefined;
    }

    let timer;
    let timer2;
    const checkMeetAuth = async () => {
      const authToken = getAuthToken();
      try {
        const { token } = authToken
          ? await getRoomToken(roomName)
          : await getRoomTokenGuest(roomName);

        setTitle(formatMessage({ id: 'VIDEO_CONF_ENTERING' }));
        timer = setTimeout(() => {
          setMeetJWT(token);
        }, 2000);
      } catch (err) {
        const { response } = err;
        const { status, data } = response || {};
        if (status === 401 && data.error_code === 'ERR_SESSION_NOT_STARTED') {
          setTitle(formatMessage({ id: 'VIDEO_CONF_WAITING_HOST' }));

          timer2 = setTimeout(checkMeetAuth, 5000);
        }
      }
    };

    checkMeetAuth();

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, [roomName, formatMessage, history]);

  const stylesPreJoin = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '80px 40px',
    height: 'calc(100vh - 200px)',
  };

  const stylesMeetWrap = {
    height: '100vh',
    width: '100vw',
  };

  function RenderLogoTitle() {
    return (
      <div>
        <img src={ImmailMeetLogo} alt="Chat and Video logo" width={60} />
        <Typography variant="h5" gutterBottom>
          Zimbra Video
        </Typography>
      </div>
    );
  }

  function RenderLoading() {
    return (
      <div>
        <CircularProgress
          size={80}
          color="primary"
          style={{ marginBottom: '40px' }}
        />
        <Typography variant="h5">{title}</Typography>
      </div>
    );
  }

  function RenderDownloadBox() {
    return (
      <div>
        <Typography variant="subtitle1" paragraph>
          <FormattedMessage
            id="VIDEO_CONF_FIRST_TIME"
            values={{ app_title: APP_TITLE }}
          />
        </Typography>
        {/* <Button
          id="btn-download-immail-meet"
          href={apps[osName].url}
          fullWidth
          color="primary"
          label={apps[osName].text}
        /> */}
      </div>
    );
  }

  function RenderLaunchBox() {
    return (
      <div>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="VIDEO_CONF_ALREADY_INSTALLED"
            values={{ app_title: APP_TITLE }}
          />
        </Typography>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          href={isMobile ? meetUrlMobile : meetUrlDesktop}
          color="primary"
          underline="none"
        >
          <Typography variant="h5">
            <FormattedMessage id="VIDEO_CONF_JOIN_MEETING_APP" />
          </Typography>
        </Link>

        <br />

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          variant="body2"
          color="primary"
          underline="none"
          href={meetUrlWeb}
          target="_blank"
          onClick={handleOpenMeetWeb}
        >
          <FormattedMessage id="VIDEO_CONF_CONTINUE_BROWSER" />
        </Link>
      </div>
    );
  }

  return (
    <div>
      {!meetJWT && (
        <div style={stylesPreJoin}>
          <RenderLogoTitle />
          <RenderLoading />
          <Footer />
        </div>
      )}

      {meetJWT && (isElectron || isMobile) && !proceedWithMeetWeb && (
        <div style={stylesPreJoin}>
          <RenderLogoTitle />
          {/* <RenderLaunchBox />
          <RenderDownloadBox /> */}
          <RenderLoading />
          <Footer />
        </div>
      )}

      {meetJWT && !isElectron && (
        <div
          className="wrapper"
          style={stylesMeetWrap}
          ref={meetContainerRef}
        />
      )}
      <DialogStartRecorderError isOpen={showErrorStartRecording} />
    </div>
  );
}

export default Meet;
