import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import log from 'utils/log';

import TextField from '../../Common/TextField';
import Link from '../../Common/Link';
import Button from '../../Common/Button';
import TitleForm from '../../Common/Title';
import SubTitle from '../../Common/SubTitle';
import { BLUE_BUTTOM, WHITE } from '../../../constants/colors.styles';
import { addTeammates } from '../../../services/signup';
import { APP_TITLE, APP_URL } from '../../../constants/http.constants';
import { errorMessagesAddTeammates } from '../../../utils/MessageError';
import { actions } from '../action';
import { isEmail } from '../../../utils/helpers';

const useStyles = makeStyles(() => ({
  span: {
    color: 'transparent',
    fontSize: 10,
    textAlign: 'end',
    position: 'absolute',
    bottom: '5%',
  },
  arrow: {
    fontSize: 10,
    margin: -2,
    marginLeft: 2,
    color: BLUE_BUTTOM,
  },
  contentNull: {
    width: 350,
  },
}));

export default function AddTeammates() {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [subTitle, setSubTitle] = useState('SUBTITLE_ADD_TEAMMATES');
  const [errorTitle, setErrorTitle] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [emails, setEmails] = useState(Array(5).fill(null));
  const [error, setError] = useState([...Array(5)]);
  const [emailDomain, setEmailDomain] = useState(null);
  const dispatch = useDispatch();
  const elRef = useRef([...Array(6)].map(() => createRef()));

  const onSubmit = useCallback(async () => {
    const err = error.filter((e) => e);
    if (err.length) {
      setErrorTitle(true);
      setSubTitle(errorMessagesAddTeammates['Invalid email was provided']);
      return;
    }

    try {
      setIsDisabled(true);

      const validEmails = emails.filter((email) => email);
      if (validEmails.length) {
        const fullEmails = validEmails.map((email) => `${email}${emailDomain}`);

        await addTeammates(fullEmails);
      }

      window.location.href = APP_URL;
    } catch (err) {
      log.logger(err);
      setSubTitle(
        errorMessagesAddTeammates[err.response.data.messages[0]] ||
          'MSG_ADD_TEAMMATES_ERR'
      );
      setErrorTitle(true);
      setIsDisabled(false);
    }
  }, [emailDomain, emails, error]);

  const onBlur = useCallback(
    (email, key) => {
      const verifyEmailValid = (value) => {
        const exists = emails.find((e) => e === email) && emails[key] !== email;
        const fullEmailIsValid = isEmail(`${value}${emailDomain}`);

        if (exists || !fullEmailIsValid) {
          return false;
        }
        return true;
      };
      const updateEmailError = (index, value) => {
        setError((prevState) => {
          const newState = [...prevState];
          newState[index] = value;
          return newState;
        });
        return value;
      };

      if (verifyEmailValid(email) || email === '') {
        setEmails((prevState) => {
          const newState = [...prevState];
          newState[key] = email;
          return newState;
        });

        return updateEmailError(key, false);
      }

      return updateEmailError(key, true);
    },
    [emailDomain, emails]
  );

  const onChange = useCallback(
    (email, key) => {
      const addInput = (key) => {
        if (key + 1 < emails.length) {
          setEmails((prevState) => {
            const newState = [...prevState];
            newState[key + 1] = '';
            return newState;
          });
        }
      };

      if (email.length === 3) {
        addInput(key);
      }
    },
    [emails]
  );

  useEffect(() => {
    dispatch(actions.step(3));

    const user = JSON.parse(localStorage.getItem('user'));
    setEmailDomain(`@${user.email.split('@')[1]}`);

    elRef.current[0].current.focus();
  }, [dispatch]);

  return (
    <>
      <TitleForm
        error={errorTitle}
        id="tit-add-teammates"
        whiteSpace="pre-line"
      >
        {formatMessage({ id: 'TITLE_ADD_TEAMMATES' })}
      </TitleForm>
      <SubTitle error={errorTitle} id="sub-add-teammates">
        {subTitle
          ? formatMessage({ id: subTitle }, { app_title: APP_TITLE })
          : null}
      </SubTitle>
      <Grid container alignItems="flex-end" justify="flex-end">
        <Grid item sm={12} xs={12}>
          {emails.map((email, key) => {
            if (email === null && key > 0) {
              return null;
            }

            return (
              <TextField
                // eslint-disable-next-line react/no-array-index-key
                key={`email-${key}`}
                id="inp-add-teammates"
                inputRef={elRef.current[key]}
                label={formatMessage({ id: 'LABEL_TYPE_TEAMMATE_EMAIL' })}
                defaultValue={email}
                onBlur={(e) => onBlur(e.target.value, key)}
                onChange={(e) => onChange(e.target.value, key)}
                style={{ marginTop: 0 }}
                type="text"
                name="addTeammates"
                error={error[key]}
                InputProps={
                  emailDomain && {
                    endAdornment: (
                      <InputAdornment position="end">
                        {emailDomain}
                      </InputAdornment>
                    ),
                  }
                }
              />
            );
          })}
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Button
            id="btn-submit-add-teammates"
            label={formatMessage({ id: 'GET_STARTED' })}
            style={{
              fontSize: 12,
              padding: 10,
              fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif', sans-serif`,
              backgroundColor: BLUE_BUTTOM,
              color: WHITE,
              marginBottom: '10%',
            }}
            startIcon=""
            endIcon={
              isDisabled ? (
                <CircularProgress size={15} style={{ color: WHITE }} />
              ) : (
                ''
              )
            }
            onClick={onSubmit}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={12} xs={12} className={classes.span}>
          <Link id="lnk-skip-add-teammates" to="/">
            {formatMessage({ id: 'SKIP' })}
          </Link>
          <ArrowForwardIosIcon color="primary" className={classes.arrow} />
        </Grid>
        <Grid className={classes.contentNull} />
      </Grid>
    </>
  );
}
