/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField2 from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { BLUE_BUTTOM } from '../../constants/colors.styles';
import { isEmail } from '../../utils/helpers';

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 2,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: BLUE_BUTTOM,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: BLUE_BUTTOM,
    },
    '& .MuiTypography-root': {
      fontSize: 12,
    },
  },
})(TextField2);

function TextField(props) {
  const {
    onChange,
    onKeyPress,
    onBlur,
    required,
    name,
    label,
    autoComplete,
    fullWidth,
    error,
    style,
    value,
    defaultValue,
    type,
    inputRef,
    InputProps,
    id,
  } = props;
  const [isValid, setIsValid] = useState(true);
  const handleChange = (e) => {
    e.preventDefault();
    switch (type) {
      case 'email':
        setIsValid(isEmail(e.target.value) || e.target.value === '');
        break;
      default:
    }

    if (onChange) onChange(e, isValid);
  };

  const handleBlur = (e) => {
    e.preventDefault();
    switch (type) {
      case 'email':
        setIsValid(isEmail(e.target.value) || e.target.value === '');
        break;
      default:
    }

    if (onBlur) onBlur(e, isValid);
  };

  return (
    <StyledTextField
      id={id}
      error={error || !isValid}
      variant="outlined"
      margin="normal"
      required={required}
      fullWidth={fullWidth}
      name={name}
      label={label}
      type={type}
      autoComplete={autoComplete}
      onChange={(e) => handleChange(e)}
      onKeyPress={(e) => onKeyPress(e)}
      onBlur={(e) => handleBlur(e)}
      style={style}
      value={value}
      defaultValue={defaultValue}
      inputRef={inputRef}
      InputProps={InputProps}
    />
  );
}

TextField.propTypes = {
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  inputRef: PropTypes.object,
  InputProps: PropTypes.object,
  id: PropTypes.string,
};

TextField.defaultProps = {
  required: false,
  fullWidth: true,
  label: '',
  autoComplete: '',
  onChange: () => {},
  onKeyPress: () => {},
  InputProps: {},
};

export default TextField;
