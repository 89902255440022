import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { useIntl, FormattedMessage } from 'react-intl';

import CachedIcon from '@material-ui/icons/CachedSharp';
import VideoCamIcon from '@material-ui/icons/Videocam';

const useStyles = makeStyles(() => ({
  containerImg: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    position: 'absolute',
    top: 48,
    left: 28,
    minWidth: 20,
    minHeight: 20,
    backgroundColor: 'white',
    borderRadius: '100%',
  },
}));

export function DialogStartRecorderError(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { isOpen } = props;
  const handleClose = () => {
    window.location.reload();
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={classes.containerImg}>
            <div style={{ position: 'relative' }}>
              <VideoCamIcon style={{ color: red[300], fontSize: 150 }} />
              <div className={classes.circle} />
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '22px' }}>
              <FormattedMessage id="MSG_ERROR_INIT_RECORDER" />
            </p>
            <p style={{ fontSize: '16px', paddingTop: 10 }}>
              <FormattedMessage id="SECOND_MSG_ERROR_INIT_RECORDER" />
            </p>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', padding: 40 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<CachedIcon />}
          >
            {formatMessage({ id: 'RELOAD_PAGE_BUTTON' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogStartRecorderError.defaultProps = {
  isOpen: false,
};

DialogStartRecorderError.propTypes = {
  isOpen: PropTypes.bool,
};
