export const PROFESSIONAL = 'PROFESSIONAL';
export const TRANSITION_TIME = 500;
export const TRANSITION_TIMEOUT = 5000;
export const CLOSE_ROOM_CHECK_MINS = 180;
export const CLOSE_ROOM_COUNT_DOWN_SECS = 60;
export const VIDEO_WIDTH = 320;

export const CHROME = 'Chrome';
export const FIREFOX = 'Firefox';
export const SAFARI = 'Safari';
export const LINUX = 'Linux';
export const WINDOWS = 'Windows';
export const OSx = 'Mac OS';

export const trim = (str) => {
  if (str) {
    return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  }

  return false;
};
