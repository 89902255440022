import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { decodeToken } from 'react-jwt';
import { checkTokenExpiration } from 'utils/auth';

export default function useInviteToken() {
  const [inviteToken, setInviteToken] = useState(null);
  const [invitedEmail, setInvitedEmail] = useState(null);
  const [expiredToken, setExpiredToken] = useState(false);

  useEffect(() => {
    const { inviteToken } = queryString.parse(window.location.search);

    if (!inviteToken) return;

    if (checkTokenExpiration(inviteToken)) {
      const { email } = decodeToken(inviteToken);
      setInviteToken(inviteToken);
      setInvitedEmail(email);
      return;
    }

    setExpiredToken(true);
  }, []);

  return [inviteToken, invitedEmail, expiredToken];
}
