/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { deviceDetect, isIOS, isMobile } from 'react-device-detect';
import ChromePNG from 'imgs/chrome-logo.png';
import FirefoxPNG from 'imgs/firefox-logo.png';
import SafariPNG from 'imgs/safari-logo.png';

import {
  getUrlDownloadChrome,
  getUrlDownloadFirefox,
} from 'constants/http.constants';

import { CHROME, FIREFOX, SAFARI, OSx } from 'constants/utils';
import MainFrame from '../MainFrame';
import SubTitle from '../Common/SubTitle';
import TitleForm from '../Common/Title';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 5),
    textAlign: 'center',
  },
  main: {
    maxWidth: 400,
  },
  txt: {
    padding: 16,
  },
  logo: {
    fontSize: 50,
  },
  img: {
    width: 50,
    height: 50,
  },
}));

const BROWSER_INFO = {
  Chrome: {
    icon: ChromePNG,
    lnk_download: getUrlDownloadChrome(),
  },
  Firefox: {
    icon: FirefoxPNG,
    lnk_download: getUrlDownloadFirefox(),
  },
  Safari: {
    icon: SafariPNG,
  },
};

export default function SupportedBrowser() {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const renderWeb = () => {
    const browser = [];
    switch (true) {
      case deviceDetect().osName === OSx:
        browser.push(CHROME, FIREFOX, SAFARI);
        break;
      case isIOS:
        browser.push(SAFARI);
        break;
      default:
        browser.push(CHROME, FIREFOX);
        break;
    }

    return (
      <Grid container justify="center">
        {browser.map((item) => (
          <Grid
            item
            sm={12 / browser.length}
            xs={12 / browser.length}
            key={`item-${item}`}
            className="browser"
          >
            <img
              src={BROWSER_INFO[item].icon}
              alt={`img-logo-${item}`}
              className={classes.img}
              id={`img-logo-${item}`}
            />
            <br />
            {item}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <MainFrame id="root-supported-browser">
      <Container
        component="main"
        className={classes.main}
        id="ctn-supported-browser"
      >
        <Paper className={classes.paper}>
          <TitleForm
            id="ttl-supported-browser"
            textAlign="center"
            whiteSpace="pre-line"
          >
            {formatMessage({ id: 'VIDEO_CONF_NOT_SUPPORTED_TITLE' })}
          </TitleForm>
          <br />
          <SubTitle id="txt-sub-supported-browser" textAlign="center">
            {formatMessage({ id: 'VIDEO_CONF_NOT_SUPPORTED' })}
          </SubTitle>
          <br />
          {renderWeb()}
          <br />

          {isMobile ? (
            <SubTitle
              id="txt-footer-mobile-supported-browser"
              textAlign="center"
            >
              {formatMessage({ id: 'VIDEO_CONF_NOT_SUPPORTED_TEXT' })}
            </SubTitle>
          ) : null}
        </Paper>
      </Container>
    </MainFrame>
  );
}
