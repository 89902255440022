/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import TextField2 from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { BLUE_BUTTOM, GREY_NUMBER } from '../../constants/colors.styles';

const StyledTextField = withStyles({
  root: {
    '& > *': {
      height: 55,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 2,
        width: '100%',
        top: 0,
        display: 'flex-grid',
        padding: 0,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: BLUE_BUTTOM,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: BLUE_BUTTOM,
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
      marginTop: 5,
      marginLeft: 10,
      textAlign: 'center',
      color: GREY_NUMBER,
    },
    '& .MuiInputBase-input': {
      '-webkit-appearance': 'none !important',
    },
  },
})(TextField2);

function TextField(props) {
  const {
    onChange,
    onKeyDown,
    onClick,
    onBlur,
    required,
    name,
    autoComplete,
    fullWidth,
    error,
    style,
    defaultValue,
    type,
    inputRef,
    value,
    disabled,
    id,
  } = props;

  return (
    <StyledTextField
      error={error}
      variant="outlined"
      margin="normal"
      required={required}
      fullWidth={fullWidth}
      name={name}
      inputProps={{ minLength: 0, maxLength: 1 }}
      type={type}
      id={id}
      autoFocus
      autoComplete={autoComplete}
      onChange={onChange}
      onKeyDown={(e) => onKeyDown(e)}
      onBlur={onBlur}
      onClick={onClick}
      style={style}
      defaultValue={defaultValue}
      inputRef={inputRef}
      value={value}
      disabled={disabled}
    />
  );
}

TextField.propTypes = {
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  inputRef: PropTypes.object,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

TextField.defaultProps = {
  required: false,
  fullWidth: true,
  autoComplete: '',
  value: '',
  onChange: () => {},
  onKeyDown: () => {},
};

export default TextField;
