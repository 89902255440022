/* eslint-disable react/require-default-props */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BLUE_BUTTOM } from '../../constants/colors.styles';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 12,
    textTransform: 'capitalize',
    color: BLUE_BUTTOM,
    textDecorationLine: 'none',
    ':visited': {
      color: BLUE_BUTTOM,
    },
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
      textDecorationLine: 'underline',
    },
  },
}));

export default function Links({ to, style, children, onClick, id, target }) {
  const classes = useStyles();

  return (
    <Link
      id={id}
      className={classes.root}
      to={to}
      style={style}
      onClick={onClick}
      target={target}
    >
      {children}
    </Link>
  );
}

Links.propTypes = {
  to: PropTypes.string.isRequired,
  style: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  id: PropTypes.string,
  target: PropTypes.string,
};
