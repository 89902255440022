import { SHOW_DIALOG, HIDE_DIALOG } from '../constants/action.types';

export const showDialog = (data) => ({
  type: SHOW_DIALOG,
  payload: data,
});

export const hideDialog = () => ({
  type: HIDE_DIALOG,
});
