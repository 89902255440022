export const errorMessagesCheckPassword = {
  'Invalid token': 'INVALID_TOKEN',
  'Max number of tries exceeded.': 'MAX_NUMBER_OF_TRIES_EXCEEDED',
  'Expired token. A new one has sent.': 'EXPIRED_TOKEN_A_NEW_ONE_HAS_SENT',
  'Other error': 'OTHER_ERROR_ST',
  'The user is already confirmed': 'THE_USER_IS_ALREADY_CONFIRMED',
  'Invalid access token...': 'INVALID_ACCESS_TOKEN',
};

export const errorMessagesSignup = {
  'Email address already in use!': 'EMAIL_ADDRESS_ALREADY_IN_USE',
  'No valid email address was provided.': 'NO_VALID_EMAIL_ADDRESS_WAS_PROVIDED',
  'No personal email allowed address without invite!':
    'SIGN_UP_PERSONAL_EMAIL_NOT_ALLOWED',
  'Free account limit reached.': 'ERR_REQUIRED_UPDATE_PLAN',
  ERR_DOMAIN_INACTIVE: 'ERR_DOMAIN_INACTIVE_IN_SIGNUP',
  ERR_ZIMBRA_DOMAIN_SIGNUP_NOT_ALLOWED: 'ERR_ZIMBRA_DOMAIN_SIGNUP_NOT_ALLOWED',
};

export const errorMessagesAddTeammates = {
  'No email address from the same domain was provided':
    'MSG_ADD_TEAMMATES_EMAIL_DOMAIN_ERR',
  'Invalid email was provided': 'MSG_ADD_TEAMMATES_INVALID_EMAIL_ERR',
  'No email address provided': 'NO_EMAIL_ADDRESS_PROVIDED',
};

export const errorMessagesLogin = {
  'E-mail e/ou senha inválidos.': 'INVALID_EMAIL_AND_OR_PASSWORD',
  'Seu cadastro ainda não foi confirmado, por favor acesse seu e-mail para confirmar.':
    'NOT_CONFIRMED_USER',
  'Could not check login. Please, try again!': 'NOT_CONFIRMED_USER',
  'Usuário não está ativo!': 'USER_INACTIVE',
  'Domain is disabled.': 'DOMAIN_INACTIVE',
};

export const errorMessagesRecoveryPassword = {
  'The email was not provided!': 'THE_EMAIL_WAS_NOT_PROVIDED',
  'This user does not exist.': 'THIS_USER_DOES_NOT_EXIST',
};

export const errorMessagesUpdatePassword = {
  'Password and password_confirmation do not match.':
    'PASSWORD_AND_PASSWORD_CONFIRMATION_DO_NOT_MATCH',
  'User validation failed: password: Password should have between 6 and 64 characters':
    'USER_VALIDATION_FAILED',
};

export const errorMessagesWelcome = {
  'Password and password_confirmation do not match.':
    'PASSWORD_AND_PASSWORD_CONFIRMATION_DO_NOT_MATCH',
  'User validation failed: password: Password should have between 6 and 64 characters':
    'USER_VALIDATION_FAILED',
  'Password must have at least 6 characters': 'USER_VALIDATION_FAILED',
};
