/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '../../Common/TextField';
import TextFieldPassword from '../../Common/TextFieldPassword';
import Button from '../../Common/Button';
import Checkbox from '../../Common/Checkbox';
import TitleForm from '../../Common/Title';
import Link from '../../Common/Link';
import SubTitle from '../../Common/SubTitle';
import { errorMessagesWelcome } from '../../../utils/MessageError';

import { actions } from '../action';
import { BLUE_BUTTOM, WHITE } from '../../../constants/colors.styles';
import { completeRegister } from '../../../services/signup';
import {
  APP_TITLE,
  // URL_ADD_TEAMMATES,
  APP_URL,
} from '../../../constants/http.constants';
import { PROFESSIONAL } from '../../../constants/utils';

export default function Welcome() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  // const history = useHistory();
  const inputRef1 = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [subTitleText, setSubTitle] = useState('WELCOME_SUBTITLE');
  const marginTop = 5;
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    password: '',
    accepted_terms: false,
  });

  const register = async () => {
    if (
      form.firstName &&
      form.lastName &&
      form.password &&
      form.accepted_terms
    ) {
      try {
        setIsDisabled(true);

        const profileNew = {
          first_name: form.firstName,
          last_name: form.lastName,
          password: form.password,
          password_confirmation: form.password,
        };

        if (form.accepted_terms) {
          profileNew.accepted_terms = {
            version: 1,
          };
        }

        await completeRegister(profileNew);

        setError(false);
        setIsDisabled(false);

        const profile = JSON.parse(localStorage.getItem('acting_profile'));

        profile.info = {
          ...profile.info,
          first_name: profileNew.first_name,
          last_name: profileNew.last_name,
        };

        localStorage.setItem('acting_profile', JSON.stringify(profile));

        if (profile.type === PROFESSIONAL) {
          // history.push(URL_ADD_TEAMMATES);
          window.location.href = APP_URL;
        } else {
          window.location.href = APP_URL;
        }
      } catch (err) {
        setIsDisabled(false);
        setError(true);
        setSubTitle(errorMessagesWelcome[err.response.data.messages[0]]);
      }
    } else {
      setError(true);

      if (!form.firstName || !form.lastName || !form.password) {
        setSubTitle('REQUIRED_FIELD');
      } else if (!form.accepted_terms) {
        setSubTitle('ACCEPT_TERMS');
      }
    }
  };

  useEffect(() => {
    dispatch(actions.step(2));
    inputRef1.current.focus();
  }, [dispatch]);

  useEffect(() => {
    if (form.firstName || form.lastName || form.password) {
      setError(false);
      setSubTitle('WELCOME_SUBTITLE');
    }
  }, [form]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        register();
      }}
      autoComplete="off"
    >
      <TitleForm error={error} id="tit-welcome">
        {formatMessage({ id: 'WELCOME_TITLE' }, { app_title: APP_TITLE })}
      </TitleForm>
      <SubTitle error={error} id="sub-welcome">
        {subTitleText ? formatMessage({ id: subTitleText }) : null}
      </SubTitle>
      <TextField
        id="inp-first-name-welcome"
        name="firstName"
        label={formatMessage({ id: 'FIRST_NAME' })}
        style={{ marginTop }}
        type="text"
        onChange={(e) => setForm({ ...form, firstName: e.target.value })}
        error={error && !form.firstName}
        inputRef={inputRef1}
        autoComplete="off"
      />
      <TextField style={{ display: 'none' }} />
      <TextFieldPassword style={{ display: 'none' }} />
      <TextField
        name="lastName"
        id="inp-last-name-welcome"
        label={formatMessage({ id: 'LAST_NAME' })}
        style={{ marginTop }}
        type="text"
        onChange={(e) => setForm({ ...form, lastName: e.target.value })}
        error={error && !form.lastName}
        autoComplete="off"
      />
      <TextFieldPassword
        name="password"
        id="inp-password-welcome"
        label={formatMessage({ id: 'PASSWORD' })}
        style={{ marginTop }}
        type="text"
        onChange={(e) => setForm({ ...form, password: e.target.value })}
        error={error && !form.password}
        autoComplete="off"
        onBlur={() => {
          if (form.password.length < 6 || form.password.length > 64) {
            setError(true);
            setSubTitle('USER_VALIDATION_FAILED');
          }
        }}
      />
      <Checkbox
        error={error}
        required
        value="accepted_terms"
        checked={form.accepted_terms}
        onChange={(e) => setForm({ ...form, accepted_terms: e.target.checked })}
      />
      <Link
        id="lnk-privacy-policy"
        to="#"
        onClick={() =>
          window.open('https://chat.zimbra.com/zimbra_network_eula.pdf')
        }
        style={{ textTransform: 'unset' }}
      >
        {formatMessage({ id: 'PRIVACY_POLICY' })}
      </Link>
      <Grid container direction="row" justify="flex-end">
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
          style={{
            padding: '0 0 30px 0',
          }}
        >
          <Button
            id="btn-register-welcome"
            color="primary"
            label={formatMessage({ id: 'REGISTER' })}
            style={{
              fontSize: 12,
              padding: 10,
              fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif', sans-serif`,
              backgroundColor: BLUE_BUTTOM,
              color: WHITE,
            }}
            disabled={isDisabled}
            startIcon=""
            endIcon={
              isDisabled ? (
                <CircularProgress size={15} style={{ color: WHITE }} />
              ) : (
                ''
              )
            }
          />
        </Grid>
        <Grid style={{ minWidth: 600 }} />
      </Grid>
    </form>
  );
}
