/**
 * @description Factory function that returns an object with functions that execute events in the videoconference api.
 * @param  {({mode='file'})=>{api.executeCommand('stopRecording'} {stopRecording
 * @param  {} mode
 * @param  {} ;}
 * @param  {({mode='file',...rest})=>{api.executeCommand('startRecording'} startRecording
 * @param  {} {mode
 * @param  {} ...rest}
 * @param  {} ;}
 * @param  {()=>{api.executeCommand('hangup'} hangup
 */
const meetEventsFactory = (api) => ({
  stopRecording: ({ mode = 'file' }) => {
    api.executeCommand('stopRecording', mode);
  },
  startRecording: ({
    mode = 'file',
    immailApiUrl = process.env.REACT_APP_API_URL,
    ...rest
  }) => {
    api.executeCommand('startRecording', {
      mode,
      immailApiUrl,
      ...rest,
    });
  },
  avatarUrl: (avatarUrl = undefined) => {
    api.executeCommand('avatarUrl', avatarUrl);
  },
  hangup: () => {
    api.executeCommand('hangup');
  },
  dispose: () => {
    api.dispose();
  },
});

export default meetEventsFactory;
