import React from 'react';

// import { Container } from './styles';

function ClearLocalStorage() {
  localStorage.clear();
  return <h1>Local storage has cleared!</h1>;
}

export default ClearLocalStorage;
