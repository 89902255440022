import React from 'react';

import Signup from '../form/Signup';
import Welcome from '../form/Welcome';
import UpdatePassword from '../form/UpdatePassword';
import AddTeammates from '../form/AddTeammates';
import Login from '../form/Login';
import RecoveryPassword from '../form/RecoveryPassword';
import CheckToken from '../form/CheckToken';

import ImageLogin from '../../../imgs/image-login.svg';
import ImageSignup from '../../../imgs/image-signup.svg';
import ImageCode from '../../../imgs/image-code.png';
import ImageAddTeammates from '../../../imgs/image-add-teammates.svg';
import ImageRecovery from '../../../imgs/image-recovery.svg';
import ImageCheck from '../../../imgs/check.svg';

const Content = {
  signup: {
    component: <Signup />,
    src: ImageSignup,
    sizeImage: 250,
    totalSteps: 4,
  },
  welcome: {
    component: <Welcome />,
    src: ImageCheck,
    sizeImage: 150,
    totalSteps: 4,
  },
  'add-teammates': {
    component: <AddTeammates />,
    src: ImageAddTeammates,
    sizeImage: 200,
    totalSteps: 4,
  },
  login: {
    component: <Login />,
    src: ImageLogin,
    sizeImage: 150,
    totalSteps: 0,
  },
  'recovery-password': {
    component: <RecoveryPassword />,
    src: ImageRecovery,
    sizeImage: 250,
    totalSteps: 3,
  },
  'check-token': {
    component: <CheckToken />,
    src: ImageCode,
    sizeImage: 200,
    totalSteps: 4,
  },
  'check-token-recovery': {
    component: <CheckToken />,
    src: ImageRecovery,
    sizeImage: 250,
    totalSteps: 3,
  },
  'update-password': {
    component: <UpdatePassword />,
    src: ImageCheck,
    sizeImage: 150,
    totalSteps: 3,
  },
};

export default Content;
