import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';

// import BgBlue from '../../../imgs/bg_blue.jpg';
import Content from './Content';
import Detail from './Detail';
import { WHITE, RED_ERROR } from '../../../constants/colors.styles';
import { TRANSITION_TIME } from '../../../constants/utils';
import MainFrame from '../../MainFrame';

const useStyles = makeStyles((theme) => ({
  form: {
    borderRadius: 40,
    position: 'relative',
    minWidth: 890,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      width: '100%',
    },
  },
  content: {
    padding: '60px 30px 10px 30px !important',
    backgroundColor: WHITE,
    borderRadius: '0 2px 2px 0',

    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 2px 2px',
      padding: '60px 30px 60px 30px !important',
    },
  },
  detail: {
    // backgroundImage: `url(${BgBlue})`,
    background: '#f2f2f2',
    borderRight: '1px solid #f2f2f2',
    borderRadius: '2px 0 0 2px',
    textAlign: 'center',
    // backgroundSize: 'cover',
    padding: '40px 0 40px 0 !important',
    display: 'grid',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  error: {
    color: RED_ERROR,
  },
}));

export default function BaseForm({ time = TRANSITION_TIME }) {
  const { componentUrl } = useParams();
  const classes = useStyles();
  const [isTransition, setIsTransition] = useState(false);

  const [CurrentContent, setContentCurrent] = useState(Content[componentUrl]);

  useEffect(() => {
    setIsTransition(false);

    setTimeout(() => {
      setContentCurrent(Content[componentUrl]);

      setTimeout(() => {
        setIsTransition(true);
      }, time);
    }, time);
  }, [componentUrl, time]);

  return (
    <MainFrame>
      <Fade in={isTransition} timeout={{ enter: time, exit: time }}>
        <Grid container className={classes.form}>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.detail}>
            <Detail
              src={CurrentContent.src}
              size={CurrentContent.sizeImage}
              totalSteps={CurrentContent.totalSteps}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.content}>
            {CurrentContent.component}
          </Grid>
        </Grid>
      </Fade>
    </MainFrame>
  );
}

BaseForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  time: PropTypes.number,
};
