import axios from 'axios';
import { API_URL, PUT, POST } from '../constants/http.constants';
import client from './client';

export const createUser = async (data) =>
  axios.post(`${API_URL}/v2/user`, data);

export const confirmUserToken = async (data) => {
  const resp = await axios.put(`${API_URL}/v2/user/confirm`, data);
  return resp;
};

export const setProfile = async (id, form) =>
  client({
    method: PUT,
    url: `${API_URL}/user/${id}`,
    data: form,
  });

export const completeRegister = async (form) =>
  client({
    method: PUT,
    url: `${API_URL}/v2/user/complete-register`,
    data: form,
  });

export const addTeammates = async (emails) =>
  client({
    method: POST,
    url: `${API_URL}/invite/teammates`,
    data: { emails },
  });

export const forgotPassword = async (data) =>
  axios.post(`${API_URL}/v2/user/forgot-password`, data);

export const confirmPasswordToken = async (data) => {
  const resp = await axios.put(
    `${API_URL}/v2/user/forgot-password/confirm`,
    data
  );
  return resp;
};

export const login = async (data) => axios.post(`${API_URL}/auth/login`, data);

export const resendConfirmation = async (email) =>
  axios.put(`${API_URL}/v2/user/resend_confirmation`, email);
