import logger from './logger';

const schemaType = {
  log: '#0044BD',
  warn: '#F1BF39',
  error: '#FF5370',
};

const log = (type, ...args) => {
  if (process.env.REACT_APP_ENV !== 'production') {
    const color = schemaType[type];
    // eslint-disable-next-line no-console
    console[type](
      `%c${type.toUpperCase()}`,
      `background:${color}; padding: 3px 5px; border-radius: 3px;  color: #fff; font-weight:600`,
      ...args
    );
  }
};

/**
 * Object with custom logs functions.
 */
const Log = {
  /**
   * Log the message of any type on the standard console
   * return <console.log>
   */
  log(...args) {
    log.call(this, 'log', ...args);
  },

  /**
   * Log the message of type Warning on the standard console
   * return <console.warn>
   */
  warn(...args) {
    log.call(this, 'warn', ...args);
  },

  /**
   * Log the message of type Error on the standard console
   * return <console.error>
   */
  error(...args) {
    log.call(this, 'error', ...args);
  },

  /**
   * Logs messages to a specialized log server, actually Sentry.
   *
   * return <void>
   */
  logger,
};

export default Log;
