/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import log from 'utils/log';
// import useReCaptcha from 'hooks/useReCaptcha';

import { isEmail } from 'utils/helpers';

import TextField from 'components/Common/TextField';
import Link from 'components/Common/Link';
import TitleForm from 'components/Common/Title';
import SubTitle from 'components/Common/SubTitle';
import Button from 'components/Common/Button';
import { GREY_SUBTITLE, BLUE_BUTTOM, WHITE } from 'constants/colors.styles';
import {
  APP_TITLE,
  URL_LOGIN,
  URL_CHECK_TOKEN,
  PUBLIC_SIGNUP,
} from 'constants/http.constants';
import { errorMessagesSignup } from 'utils/MessageError';

import { createUser } from 'services/signup';
import { actions } from '../action';

const useStyles = makeStyles(() => ({
  span: {
    color: GREY_SUBTITLE,
    fontSize: 10,
    textAlign: 'end',
    marginTop: '20%',
  },
}));

export default function Signup() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRef = useRef(null);
  // const { checkRecaptcha } = useReCaptcha();

  const [subTitle, setSubTitle] = useState('SIGN_UP_TO_EMAIL_SUBTITLE');

  const setEmailInLocalStorage = () => {
    localStorage.setItem('tokenValidationEmail', email);
    history.push(URL_CHECK_TOKEN);
  };

  const register = async () => {
    // const retoken = await checkRecaptcha('signup');

    if (!isEmail(email)) {
      setError(true);
      setSubTitle('SIGN_UP_INVALID_EMAIL');
      return;
    }

    try {
      setIsDisabled(true);
      const resp = await createUser({ email });
      setError(false);
      setSubTitle('SIGN_UP_TO_EMAIL_SUBTITLE');

      if (resp.status === 201) setEmailInLocalStorage();
    } catch (err) {
      setIsDisabled(false);

      switch (err.response.status) {
        case 400:
          log.logger(err);
          setError(true);
          if (
            Object.prototype.hasOwnProperty.call(
              err.response.data,
              'error_code'
            ) &&
            err.response.data.error_code === 'ERR_DOMAIN_INACTIVE'
          ) {
            setSubTitle(errorMessagesSignup[err.response.data.error_code]);
          } else {
            setSubTitle(errorMessagesSignup[err.response.data.message]);
          }
          break;
        case 409:
          switch (err.response.data.message) {
            case 'Email address already in use!':
              setError(true);
              setSubTitle(errorMessagesSignup[err.response.data.message]);
              break;
            default:
              setEmailInLocalStorage();
          }
          break;
        case 401:
          setError(true);

          if (err.response?.data?.error_code) {
            setSubTitle(errorMessagesSignup[err.response.data.error_code]);
          } else {
            log.logger(err);
            setSubTitle('MSG_REQUEST_ERROR');
          }
          break;
        default:
          log.logger(err);
          setError(true);
          setSubTitle('MSG_REQUEST_ERROR');
      }
    }
  };

  useEffect(() => {
    if (!PUBLIC_SIGNUP) history.push(URL_LOGIN);

    dispatch(actions.step(0));
    inputRef.current.focus();
  }, []);

  return (
    <form
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();

        register();
      }}
    >
      <TitleForm error={error} id="tit-sign-up">
        {formatMessage({ id: 'SIGN_UP_TO_EMAIL' }, { app_title: APP_TITLE })}
      </TitleForm>
      {subTitle ? (
        <SubTitle error={error} id="sub-sign-up">
          {formatMessage(
            { id: subTitle },
            {
              domain: `@${email.split('@').pop()}`,
              app_title: APP_TITLE,
            }
          )}
        </SubTitle>
      ) : null}
      <TextField
        id="inp-email-sign-up"
        name="toEmail"
        label={formatMessage({ id: 'YOUR_PROFESSIONAL_EMAIL' })}
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        error={error}
        inputRef={inputRef}
      />
      <Grid container alignItems="flex-end" justify="flex-end">
        <Grid item md={4} sm={4} xs={12}>
          <Button
            id="btn-go-sign-up"
            label={formatMessage({ id: 'GO' })}
            style={{
              fontSize: 12,
              padding: 10,
              backgroundColor: BLUE_BUTTOM,
              color: WHITE,
            }}
            endIcon={
              isDisabled ? (
                <CircularProgress size={15} style={{ color: WHITE }} />
              ) : (
                <ArrowForwardIcon />
              )
            }
            startIcon=""
            onSubmit={() => register()}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={12} xs={12} className={classes.span}>
          <span>{formatMessage({ id: 'ALREADY_HAVE_ACCOUNT' })} </span>
          <Link id="lnk-login-sign-up" to={URL_LOGIN}>
            {formatMessage({ id: 'LOGIN' })}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}
