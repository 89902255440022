export const WHITE = '#FFFFFF';
export const GREY_TITLE = '#333333';
export const GREY_SUBTITLE = '#808080';
export const GREY_LIGHT = '#CBCBCB';
export const GREY_NUMBER = '#979EA5';
export const YELLOW = '#FFCA01';
export const BLUE_LINK = '#0088c1';
export const BLUE_BUTTOM = '#0088c1';
export const RED_ERROR = '#FF4B36';
export const BLUE_LIGHT = '#1BDCF5';
export const ORANGE = '#e8822c';
export const GREEN_LIGHT = '#76ff03';
export const DISABLED = '#00000042';
export const VIDEO_CONFERENCE_COLOR = [
  '#b71c1c',
  '#ad1457',
  '#6a1b9a',
  '#4527a0',
  '#283593',
  '#0d47a1',
  '#01579b',
  '#006064',
  '#00695c',
  '#1b5e20',
  '#33691e',
  '#827717',
  '#f57f17',
  '#ff6f00',
  '#e65100',
  '#bf360c',
  '#3e2723',
  '#424242',
  '#37474f',
];

export const generateColor = () =>
  VIDEO_CONFERENCE_COLOR[
    Math.floor(Math.random() * VIDEO_CONFERENCE_COLOR.length)
  ];
